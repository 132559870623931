
.list{
  display:flex;
  -webkit-box-flex: 0;
  flex: 0 0 100%;
  max-width: 100%;
  flex-wrap: wrap;
  position: relative;
}

.list a.blog-item{
  width: 100%;
}
.list-item a{
  color:#fff;
}
.list-item{
  width: 100%;
  position: relative;
  margin-bottom:.2em;
  color:#fff;
  cursor: pointer;
}

.list-item div.itembox{
  position: absolute;
  top:.8vw;
  left:0;
  padding:.8vw 2.5vw;
  text-align: left;
}
.list-item h2{
  position: relative;
  margin: 0;
  padding: 0;
  font-size:2.2vw;
  text-align: left;
  font-weight: bold;
  line-height: 1.2em;
}
.list-item img,
.list-item video{
  width: 100%;
  object-fit: cover;
}
.list-item div.main-list-metainfo{
  position: relative;
  text-align: left;
  font-size:1.3vw;
  padding-left:0.2vw;
  margin-bottom:0.2vw;
}

.list-sml{
  display:flex;
  -webkit-box-flex: 0;
  flex: 0 0 100%;
  max-width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  justify-content:start;
  cursor: pointer;
}
.list-sml-item{
  width: 100%;
  height:100%;
  overflow: hidden;
  position: relative;
  margin-bottom:.1vw;
  color:#fff;

}
.list-sml-item.right{
  height: auto;
}
.list-sml-item div.itembox{
  position: absolute;
  bottom:.8vw;
  left:0;
  padding:0 .8vw;
  line-height: 1.2vw;
  text-align: left;
}
.list-sml-item h2{
  position: relative;
  margin: 0;
  padding: 0;
  font-size:1.5em;
  text-align: left;
  font-weight: bold;
  line-height: 1.5vw;
}
.list-sml-item img{
  width: 100%;
    height: 100%;
}
.list-sml-item div.main-list-metainfo{
  position: relative;
  text-align: left;
  font-size:1.2vw;
  margin-bottom:0.1vw;

}

@media screen and (max-width: 639px) {
  .list-item{
    margin-bottom:.2em;
  }
  .list-item div.itembox{
    padding:0 1em;
  }
  .list-item h2{
    font-size:2em;
    font-weight: bold;
    line-height: 1.2em;
  }
  .list-item img{
    width:100%;
  }
  .list-item div.main-list-metainfo{
    font-size:1.2em;
    padding-left:0.2em;
  }


  .list-sml-item{
    width: 185px;
      height:120px;
    margin-bottom:.1em;

  }

  .list-sml-item.right{
    height:auto;
    width:100%;
  }

  .list-sml-item div.itembox{
    position: absolute;
    bottom:1em;
    left:0;
    padding:0 .5em;
    text-align: left;
  }
  .list-sml-item h2{
    font-size:1.1vw;
    font-weight: bold;
    line-height: 1.2vw;
  }
  .list-sml-item img{
    width:100%;
  }
  .list-sml-item div.main-list-metainfo{
    font-size:1em;
    padding-left:0.2em;
  }

}
.movie_detail{
  margin-top: 10px;
}

.youtube {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}
.youtube iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}