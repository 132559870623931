.item-detail h2{
  position: relative;
  margin: 1.5em 0 1em 0;
  padding: 0;
  font-size:2.5em;
  text-align: left;
  font-weight: bold;
  line-height: 1.2em;
}

.item-detail div.main-detail-category{
  position: relative;
  text-align: left;
  font-size:1.5em;
  margin-bottom:.5em;
}
.item-detail div.main-detail-date{
  position: relative;
  text-align: left;
  font-size:1.5em;
  margin-bottom:0;
}

.item-detail article{
  margin-top:2em;
  font-size: 1.8em;
  text-align: left;
  line-height: 2em;

}
.item-detail article img{
  width: 100%;
  height: auto;
  margin-bottom: 1em;
}
@media screen and (max-width: 639px) {
  .item-detail{
    margin: 0 1.5em;
  }
  .item-detail h2{
    font-size:2em;
  }
  .item-detail div.main-detail-category{
    font-size:1.2em;
  }
  .item-detail div.main-detail-date{
    font-size:1.2em;
  }
  .item-detail article{
    font-size: 1.6em;
    line-height:1.8em;

  }
}
